import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import SEO from "@/components/seo"
import { getLocalizationKey } from "../utils/localize"

const DynamicPage = ({ data, pageContext }) => {
  const { contentSections, metadata, localizations } = data.strapi.page
  const { global, pricing, scheduleDemo } = data.strapi
  const localizationKeys = data.strapi.localizationKey.keyValuePairs.reduce((pv, cv) => { pv[cv.key] = cv.value; return pv; }, {});
  const getLocalization = getLocalizationKey.bind(null, pageContext.localizationKeys);

  useEffect(() => {
    window.addEventListener("load", function () {
      window.cookieconsent.initialise({
        "palette": {
          "popup": {
            "background": "#7d8790",
            "text": "#ffffff"
          },
          "button": {
            "background": "#7d8790",
            "text": "#ffffff"
          }
        },
        "theme": "edgeless",
        "position": "bottom",
        "content": {
          "message": global.cookieConsent.message,
          "dismiss": global.cookieConsent.acceptCookiesText,
          "link": global.cookieConsent.privacyPolicyLink.text,
          "href": global.cookieConsent.privacyPolicyLink.url,
        }
      })
    });
  }, []);

  return (
    <>
      <SEO seo={metadata} pageContext={{ ...pageContext, localizations, localizationKeys }} />
      <Layout global={global} pageContext={{ ...pageContext, localizations, localizationKeys }}>
        <Sections scheduleDemo={scheduleDemo} pricing={pricing} sections={contentSections} pageContext={{ ...pageContext, localizations, localizationKeys }} />
      </Layout>
    </>
  )
}

export default DynamicPage

export const query = graphql`
  query DynamicPageQuery($id: ID!, $locale: String!) {
    strapi {
      global(locale: $locale) {
        footer {
          id
          logo {
            id
            mime
            alternativeText
            url
            id
            mime
            urlSharp {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          urls {
            url
            text
            newTab
            id
          }
          smallText1
          smallText2
          smallText3
          media {
            instagram
            facebook
            twitter
            linkedIn
          }
        }
        id
        metaTitleSuffix
        metadata {
          id
          metaDescription
          metaTitle
          twitterCardType
          twitterUsername
        }
        navbar {
          button {
            id
            newTab
            text
            type
            url
          }
          id
          rightlinks {
            url
            text
            newTab
            id
          }
          leftlinks {
            url
            text
            newTab
            id
          }
          logo {
            id
            mime
            alternativeText
            url
            id
            mime
            urlSharp {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        notificationBanner {
          id
          text
          type
        }
        cookieConsent {
          id
          message
          acceptCookiesText
          privacyPolicyLink {
            id
            url
            newTab
            text
          }
        }
      }
      localizationKey(locale: $locale) {
        keyValuePairs {
          id
          value
          key
        }
      }
      scheduleDemo(locale: $locale) {
        id
        image {
          id
          mime
          alternativeText
          url
          id
          mime
          urlSharp {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        thankYouMessage
      }
      pricing(locale: $locale) {
        id
        pricing {
          id
          title
          plans {
            licenseSize
            features {
              id
              name
            }
            id
            isRecommended
            name
            price
            priceRange {
              id
              count
              amount
            }
            defaultRangeValue
            pricePeriod
            button {
              id
              text
              type
              url
              newTab
            }
            description
          }
        }
      }
      page(id: $id) {
        slug
        shortName
        metadata {
          metaTitle
          metaDescription
          shareImage {
            id
            mime
            url
          }
        }
        localizations {
          id
          locale
          slug
        }
        contentSections {
          ... on Strapi_ComponentSectionsPricing {
            __typename
            id
          }
          ... on Strapi_ComponentFormsFreeTrial {
            __typename
            id
            termsAndConditions
            thankYouMessage
          }
          ... on Strapi_ComponentFormsScheduleDemo {
            __typename
            id
          }
          ... on Strapi_ComponentFormsContactUs {
            __typename
            id
            contactInformation
            confirmationMessage
          }
          ... on Strapi_ComponentFormsSignUp {
            __typename
            id
            termsAndConditions
            thankYouMessage
          }
          ... on Strapi_ComponentSectionsBottomActions {
            __typename
            id
            title
            buttons {
              id
              newTab
              text
              type
              url
            }
          }
          ... on Strapi_ComponentSectionsHero {
            __typename
            id
            title
            description
            picture {
              id
              mime
              alternativeText
              url
            }
          }
          ... on Strapi_ComponentSectionsCarousel {
            __typename
            id
            items {
              title
              body
              button {
                id
                text
                type
              }
              linkButton {
                id
                newTab
                text
                type
                url
              }
              media {
                id
                mime
                alternativeText
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsHomeHero {
            __typename
            id
            bullets {
              id
              frames_seconds
              text
            }
            buttons {
              id
              newTab
              text
              type
              url
            }
            title
            description
            animationLanguage
            picture {
              id
              mime
              alternativeText
              url
            }
          }
          ... on Strapi_ComponentSectionsPriceTable {
            __typename
            id
            features {
              featureName
              individual
              team
              enterprise
            }
          }
          ... on Strapi_ComponentSectionsFaq {
            __typename
            id
            title
            accordion {
              id
              header
              body
            }
          }
          ... on Strapi_ComponentSectionsQuote {
            __typename
            id
            quoteText
            author
          }
          ... on Strapi_ComponentSectionsLargeText {
            __typename
            id
            text
            media {
              id
              mime
              url
              alternativeText
            }
          }
          ... on Strapi_ComponentSectionsFeatureColumnsGroup {
            __typename
            id
            title
            FeatureRows {
              id
              feature1
              feature2
              feature3
            }
            button {
              id
              newTab
              text
              type
              url
            }
          }
          ... on Strapi_ComponentSectionsNumberFeatureRowsGroup {
            __typename
            id
            numberLeadingImagePlacement: leadingImagePlacement
            features {
              description
              id
              media {
                id
                mime
                url
                alternativeText
              }
              title
            }
          }
          ... on Strapi_ComponentSectionsBulletFeatureRowsGroup {
            __typename
            id
            bulletLeadingImagePlacement: leadingImagePlacement
            features {
              description
              id
              bullets {
                id
                text
              }
              button {
                id
                type
                newTab
                text
                url
              }
              media {
                id
                mime
                url
                alternativeText
              }
              title
            }
          }
          ... on Strapi_ComponentSectionsRichTextFeatureRowsGroup {
            __typename
            id
            richTextLeadingImagePlacement: leadingImagePlacement
            features {
              description
              id
              button {
                id
                type
                newTab
                text
                url
              }
              media {
                id
                mime
                url
                alternativeText
              }
              title
            }
          }
          ... on Strapi_ComponentSectionsExamplesCategoryAnchorLinks {
            __typename
            id
            categories {
              text
            }
          }
          ... on Strapi_ComponentSectionsExamplesGroup {
            __typename
            id
            category
            examples {
              title
              description
              image {
                id
                mime
                url
                alternativeText
              }
              url
            }
          }
          ... on Strapi_ComponentSectionsLargeVideo {
            __typename
            id
            description
            title
            poster {
              id
              mime
              alternativeText
              url
            }
            video {
              alternativeText
              url
            }
          }
          ... on Strapi_ComponentSectionsLargeVideoLink {
            __typename
            id
            embed
          }
          ... on Strapi_ComponentSectionsLargeImage {
            __typename
            id
            text
            image {
              id
              mime
              url
              alternativeText
            }
          }
          ... on Strapi_ComponentSectionsRichText {
            __typename
            id
            content
          }
        }
      }
    }
  }
`
